.container {
  padding: 20px;
  margin-top: 100px;
  /* border: 1px solid red; */
}
.servicesTitle {
  text-align: center;
  font-weight: 900;
  margin-bottom: 60px;
}

.row {
  /* border: 1px solid blue; */
  margin: auto;
  margin-bottom: 20px;
}

.cell {
  /* border: 1px solid blue; */
  width: 50%;
  margin: auto;
  margin-top: 40px;
  opacity: 0.8;
  transition: 0.5s;
}

.cell:hover {
  opacity: 1;
  transform: scale(1.1);
}

.icon {
  display: flex;
  margin: auto;
  width: 120px;
  height: 120px;
}

.name {
  text-align: center;
  margin-top: 10px;
}

.recruitmentProcessContainer {
  margin: auto;
  margin-top: 100px;
}
.recruitmentProcessBody {
  display: flex;
  justify-content: center;
}
.recruitmentProcessBodyText {
  display: flex;
  align-items: center;
  margin-right: 80px;
  font-size: 21px;
  line-height: 44px;
}

.recruitmentProcessImage {
  width: 300px;
  /* height: 300px; */
}

@media only screen and (max-width: 900px) {
  .icon {
    width: 80px;
    height: 80px;
  }
  .recruitmentProcessBody {
    display: block;
  }
  .recruitmentProcessBodyText {
    width: 100%;
  }
  .recruitmentProcessImage {
    margin: auto;
    display: flex;
  }
}

@media only screen and (max-width: 400px) {
  .row {
    display: block !important;
  }
}
