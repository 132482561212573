.logo{
    width: 10%;
    max-width: 105px;
    min-width: 35px;
}
@media (max-width: 400px){
    .navbar-brand{
        margin: auto !important;
        font-size: 1rem !important;
    }
}
@media (max-width: 400px){
    .navbar-brand{
        font-size: 0.7rem !important;
    }
}