.home {
  display: flex;
  flex-wrap: wrap;
  /* margin: 4% -2.5%; */
  /* max-width: 105%; */
  width: 90%;
  margin: auto;
  overflow: hidden;
}
.homeHero {
  z-index: 1;
  width: 50%;
  margin: auto;
  display: block;
  padding-top: 10rem;
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.homeTitleBox {
  z-index: 1;
  align-self: center;
  /* padding-right: 1.4rem; */
  animation: fadeIn 1s;
  margin: auto;
  margin-top: 10em;
  color: white !important;
  text-align: center;
}
@media (max-width: 1380px) {
  .homeTitleBox {
    width: 90%;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-top: 10em;
  }
  .homeHero {
    padding-top: 6em;
  }
}

@media (min-width: 1400px) {
  .fileUploadContainer {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .homeMain,
  .aboutMain {
    font-size: 2.5em !important;
  }
  .homeSub {
    font-size: 1.5em !important;
  }
  .homeHero,
  .aboutHero {
    width: 70% !important;
  }
}
.homeMain,
.homeSub {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  text-align: center;
  z-index: 1;
}

.homeSub {
  font-size: 2rem;
  font-weight: 400;
}

.aboutText {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 47px;
  /* animation: fadeIn 1s; */
}

.about {
  width: -webkit-fill-available;
  padding-top: 20em;
  animation: fadeIn 1s;
}
.aboutHero {
  width: 30%;
  margin: auto;
  animation: fadeIn 1s;
}
.contactHero {
  margin-right: 0;
}
.aboutMain {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  text-align: center;
}
.textImageDivider {
  display: flex;
  flex-wrap: wrap;
  /* margin: 4% -2.5%; */
  /* max-width: 105%; */
  width: 80%;
  margin: auto;
  padding-top: 5em;
}
.aboutTitleBox {
  width: 50%;
}

.form {
  padding-bottom: 10em;
}
.invalid {
  color: red;
}

.submitMessage {
  font-weight: 600;
  display: flex;
  padding-top: 1em;
}

.valid {
  color: rgb(105, 175, 0);
}

.videoSection {
  overflow: hidden;
  min-height: 500px;
  width: 100%;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  background: #5db11e;
  z-index: 0;
  background: linear-gradient(180deg, #468517 0, #abdb26 120%);
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  max-width: none;
  min-height: 100%;
  opacity: 0.12;
}
.companiesSection {
  /* border: 5px solid black; */
  width: 80%;
  margin: auto;
  margin-top: 12em;
  text-align: center;
}
.companies {
  /* display: flex; */
  /* justify-content: space-evenly; */
  width: 100%;
  margin: auto;
  margin-top: 3em;
}
.companiesTitle {
  margin-bottom: 1em;
}
.companyImage {
  align-self: center;
  max-width: 190px;
  max-height: 200px;
  /* margin:3em; */
}

.react-multi-carousel-item {
  align-items: center;
  align-content: center;
  align-self: center;
}

@media (max-width: 620px) {
  .companyImage {
    margin: 0 0 2em 0;
  }
  /* .chikn{
        margin-bottom: 0;
        margin-top: 1em;
    } */
}
@media only screen and (max-width: 1000px) {
  .aboutText {
    text-align: center;
  }
}

@media (max-width: 1000px) {
  .aboutHero {
    width: 50%;
  }
  .form {
    padding-top: 6em !important;
  }

  .aboutTitleBox {
    width: 100% !important;
    padding-top: 5em;
  }
  .aboutSection {
    padding-top: 2em !important;
  }

  .textImageDivider {
    padding-top: 0 !important;
  }
}
