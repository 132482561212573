.carouselContainer{
    max-height: 300px;
}
.test{
    width: 300px !important;
}
.companyImage{
    /* border: 1px solid black; */
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /* user-drag: none; */
}
.vericalAlign{
    transform: translateY(50%);
}